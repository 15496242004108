import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Container, Row, Col } from 'react-bootstrap';
import c from 'classnames';
import LinkButton from '../LinkButton/LinkButton';
import * as styles from './feedbacks.module.scss';

const Feedbacks = ({ feedbacks }) => {
  const secondColumnStart = Math.floor(feedbacks.feedback.length / 2);
  const firstColumn = feedbacks.feedback.slice(0, secondColumnStart);
  const secondColumn = feedbacks.feedback.slice(secondColumnStart);
  const intl = useIntl();

  return (
    <Container className={styles.feedbackContainer}>
      <Row>
        <Col className={c(styles.section, 'text-center')}>
          <h3 className="title">{feedbacks.title}</h3>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          {firstColumn.map((feedback, index) => (
            <div key={index}>
              <p className={styles.paragraph}>{feedback.paragraph}</p>
              <p className={styles.name}>{feedback.name}</p>
            </div>
          ))}
        </Col>
        <Col md={6}>
          {secondColumn.map((feedback, index) => (
            <div key={index}>
              <p className={styles.paragraph}>{feedback.paragraph}</p>
              <p className={styles.name}>{feedback.name}</p>
            </div>
          ))}
        </Col>
        <Col className={c(styles.button, 'text-center')}>
          <LinkButton
            path="/enterior/services"
            title={intl.formatMessage({ id: 'button.check_services' })}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Feedbacks;
