import * as React from 'react';
import { graphql } from 'gatsby';
import { Container } from 'react-bootstrap';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../../../components/Layout';
import SEO from '../../../components/SEO/SEO';
import Header from '../../../components/Header/Header';
import Projects from '../../../components/Projects/Projects';
import Visuals from '../../../components/Visuals/Visuals';
import Slider from '../../../components/Slider/Slider';
import Feedbacks from '../../../components/Feedbacks/Feedbacks';
import BreadCrumbs from '../../../components/BreadCrumbs/BreadCrumbs';
import * as styles from '../../../components/Header/header.module.scss';
import { LineIcon } from '../../../images/index';

const PortfolioPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const seoData = {
    markdownRemark: {
      frontmatter: {
        en: data.markdownRemark.frontmatter.en.seo,
        hu: data.markdownRemark.frontmatter.hu.seo
      }
    }
  };
  const intl = useIntl();
  const langData = intl.locale === 'hu' ? frontmatter.hu : frontmatter.en;
  const crumbs = ['home', 'enterior', langData.heading];

  return (
    <Layout>
      <SEO seoData={seoData} />
      <Header className={styles.bcgColor}>
        {langData.heading.split('\n\n').map((line, index) => (
          <h1 className={styles.headerTitle} key={index}>
            {line}
          </h1>
        ))}
        <LineIcon className={styles.lineIcon} />
      </Header>
      <BreadCrumbs crumbs={crumbs} className="bg-grey" />
      <Container
        fluid
        className="bg-grey"
        style={{ border: '2px solid #e8e8e8 ' }}
      >
        <Projects project={langData.project} />
        <Visuals visual={langData.visual} />
        <Slider slider={langData.slider} />
      </Container>
      <Feedbacks feedbacks={langData.feedbacks} />
    </Layout>
  );
};
export default PortfolioPage;

export const pageQuery = graphql`
  query PortfolioPageTemplate {
    markdownRemark(fileAbsolutePath: { regex: "/portfolio.md/" }) {
      frontmatter {
        en {
          heading
          project {
            title
            projects {
              title
              link
              image {
                childImageSharp {
                  gatsbyImageData(width: 306, height: 204)
                }
              }
              imageAlt
            }
          }
          visual {
            title
            visuals {
              title
              link
              image {
                childImageSharp {
                  gatsbyImageData(width: 306, height: 204)
                }
              }
              imageAlt
            }
          }
          slider {
            title
            images {
              childImageSharp {
                gatsbyImageData(width: 1440)
              }
            }
          }
          feedbacks {
            title
            feedback {
              paragraph
              name
            }
          }
          seo {
            seoTagList {
              seoTagKey
              seoTagValue
            }
            siteTitle
            siteDescription
          }
        }
        hu {
          heading
          project {
            title
            projects {
              title
              image {
                childImageSharp {
                  gatsbyImageData(width: 306, height: 204)
                }
              }
              imageAlt
              link
            }
          }
          visual {
            title
            visuals {
              title
              link
              image {
                childImageSharp {
                  gatsbyImageData(width: 306, height: 204)
                }
              }
              imageAlt
            }
          }
          slider {
            title
            images {
              childImageSharp {
                gatsbyImageData(width: 1440)
              }
            }
          }
          feedbacks {
            title
            feedback {
              paragraph
              name
            }
          }
          seo {
            seoTagList {
              seoTagKey
              seoTagValue
            }
            siteTitle
            siteDescription
          }
        }
      }
    }
  }
`;
