import React from 'react';
import Slider from 'react-slick';
import { Col, Container, Row } from 'react-bootstrap';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import c from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as styles from './slider.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SliderLeftArrowIcon, SliderRightArrowIcon } from '../../images/index';

const ImgSlider = ({ slider }) => {
  const options = {
    caption: {
      showCaption: false
    }
  };

  const SlickArrowRight = (props) => {
    const { className, onClick } = props;
    return <SliderRightArrowIcon className={className} onClick={onClick} />;
  };

  const SlickArrowLeft = (props) => {
    const { className, onClick } = props;
    return <SliderLeftArrowIcon className={className} onClick={onClick} />;
  };

  const settings = {
    infinite: false,
    swipeToSlide: true,
    slidesToShow: 4,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <SimpleReactLightbox>
      <SRLWrapper options={options}>
        <Container>
          <Row>
            <Col className={c(styles.section, 'text-center')}>
              <h3 className="title">{slider.title}</h3>
            </Col>
          </Row>
          <Row className={c(styles.sliderSection, 'pb-5')}>
            <Col className="text-center pb-5">
              <Slider {...settings}>
                {slider.images &&
                  slider.images.map((image, index) => (
                    <div className="px-2" key={index}>
                      <GatsbyImage
                        key={index}
                        image={getImage(image)}
                        alt={`slider ${index}`}
                        className="image"
                      />
                    </div>
                  ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </SRLWrapper>
    </SimpleReactLightbox>
  );
};

export default ImgSlider;
