import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import c from 'classnames';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as styles from './projects.module.scss';

const Projects = ({ project }) => {
  return (
    <Container>
      <Row className={styles.titleSection}>
        <Col className="text-center">
          <h3 className="title">{project.title}</h3>
        </Col>
      </Row>
      <Row className={c(styles.imgSection, 'text-center')}>
        {project.projects.map((project, index) => (
          <Col md={4} className="text-center" key={index}>
            <Link to={'/albums/' + project.link}>
              <GatsbyImage
                key={index}
                image={getImage(project.image)}
                alt={project.imageAlt}
                className="image"
              />
              <p className={c(styles.imgName, 'mt-2')}>{project.title}</p>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Projects;
