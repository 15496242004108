import React from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import c from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as styles from './visuals.module.scss';

const Visuals = ({ visual }) => {
  return (
    <Container>
      <Row className={styles.titleSection}>
        <Col className="text-center">
          <h3 className="title">{visual.title}</h3>
        </Col>
      </Row>
      <Row className={c(styles.imgSection, 'text-center')}>
        {visual.visuals.map((visual, index) => (
          <Col md={4} className="text-center" key={index}>
            <Link to={'/albums/' + visual.link}>
              <GatsbyImage
                key={index}
                image={getImage(visual.image)}
                alt={visual.imageAlt}
                className="image"
              />
              <p className={c(styles.imgName, 'mt-2')}>{visual.title}</p>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Visuals;
